
import { IonFab } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
    name: "FatButtonFloat",
    props: {
        pushup: Boolean,
    },
    components: {
        IonFab,
    },
});
