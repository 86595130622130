import Vuex from "vuex";

import PartyDetailsModalModel from "./models/PartyDetailsModalModel";
import PartyDetailsModel from "./models/PartyDetailsModel";
import PartySearchModel from "./models/PartySearchModel";
import OSM from "ol/source/OSM";
import { transform as transformCoords } from "ol/proj";
const SOURCE_PROJECTION = "EPSG:4326", OSM_PROJECTION = new OSM().getProjection();
import PartyPlaceModel from "./models/PartyPlaceModel";
import TrendingPageModel from "./models/TrendingPageModel";
import TownsModalModel from "./models/TownsModalModel";
import TownModel from "./models/TownModel";
import { Map as GeoMap, Overlay } from "ol";
import TrendingSearchModalModel from "./models/TrendingSearchModalModel";
import ProfileModel from "./models/ProfileModel";
import FriendsModalModel from "./models/FriendsModalModel";
import ProfileModalModel from "./models/ProfileModalModel";
import FriendSearchModalModel from "./models/FriendSearchModalModel";
import PlaceSearchModel from "./models/PlaceSearchModel";
import PlaceDetailsModalModel from "./models/PlaceDetailsModalModel";
import PartyJoinModalModel from "./models/PartyJoinModalModel";
import InviteFriendsModalModel from "./models/InviteFriendsModalModel";

export default new Vuex.Store({
    state: {
        partyDetailsModal: new PartyDetailsModalModel(),
        availableParties: Array<PartyDetailsModel>(
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 0
                party.title = "UV Color party"
                party.locationTown = "Nowherton"
                party.locationStreet = "Winter st."
                party.locationHouseNumber = "2302"
                party.timeFrom = new Date(Date.parse("2023-11-11T19:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-11-12T02:30:00.000000Z"))
                party.imageLocation = "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                party.description = `Gray weekdays are over…!👀😜\n\nLet the colors of the weekend shine again!\n\nUV colors will light up the night! 🤩\n\nMeet at the party!`
                party.price = 65;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 1
                party.title = "An Evening with Itzhak Perlman"
                party.locationTown = "Akron"
                party.locationStreet = "Hill St"
                party.locationHouseNumber = "198"
                party.timeFrom = new Date(Date.parse("2023-10-12T20:30:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-10-12T03:00:00.000000Z"))
                party.imageLocation = "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?cs=srgb&dl=pexels-vishnu-r-nair-1105666.jpg&fm=jpg"
                party.description = `Undisputedly one of the world’s most respected violinists and music educators, he’s also beloved by generations through appearances on The Ed Sullivan Show to Sesame Street to The Late Show with Stephen Colbert`
                party.price = 50;
                party.age = 16;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 2
                party.title = "Big Time Rush"
                party.locationTown = "Cuyahoga"
                party.locationStreet = "Corners Rd"
                party.locationHouseNumber = "1145"
                party.timeFrom = new Date(Date.parse("2023-12-01T20:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-12-02T02:30:00.000000Z"))
                party.imageLocation = "https://images.pexels.com/photos/154147/pexels-photo-154147.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                party.description = `Fans of Big Time Rush across the country are going crazy in anticipation of the band's nationwide tour`
                party.price = 45;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 3
                party.title = "TINK & Friends"
                party.locationTown = "Cleveland"
                party.locationStreet = "Euclid Ave"
                party.locationHouseNumber = "5000"
                party.timeFrom = new Date(Date.parse("2023-11-14T08:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-11-14T21:00:00.000000Z"))
                party.imageLocation = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3acA-0JA3zUXmuYsWS2X__LXgdLp-ol7uuQ&usqp=CAU"
                party.description = `Multi-talented R&B musician and lyricist, Tink will perform live at Agora Theatre on April 22 at 8:00PM.`
                party.price = 70;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 4
                party.title = "Fall Out Boy"
                party.locationTown = "Cuyahoga Falls"
                party.locationStreet = "Steels Corners Rd"
                party.locationHouseNumber = "1145"
                party.timeFrom = new Date(Date.parse("2023-11-15T20:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-11-15T02:00:00.000000Z"))
                party.imageLocation = "https://www.collinsdictionary.com/images/full/concert_295115348.jpg"
                party.description = `Fans are going absolutely nuts for Fall Out Boy's new tour, dubbed "So Much For (Tour) Dust." `
                party.price = 35;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 5
                party.title = "The Cure"
                party.locationTown = "Cuyahoga Falls"
                party.locationStreet = "Steels Corners Rd"
                party.locationHouseNumber = "1145"
                party.timeFrom = new Date(Date.parse("2023-12-10T19:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-13-11T07:03:00.000000Z"))
                party.imageLocation = "https://images.squarespace-cdn.com/content/v1/5abff79655b02ce1f785fddd/1554691968970-AAAJGCZR8AVA835891HD/PSFix_20190125_150305.jpeg?format=2500w"
                party.description = `It's time to save those tears and rock the night away as The Cure embarks on their first-ever North American Tour in seven years.`
                party.price = 55;
                return party;
            })()
        ),
        partySearch: new PartySearchModel(),
        trendingPage: new TrendingPageModel(),
        trendingPlaces: Array<PartyPlaceModel>(
                        ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_8f65aac0-456d-4487-86c9-cd3057999100";
                partyPlace.coords = [41.1006926, -81.4667767];
                partyPlace.name = "Ozzy's Nottingham Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMgOZ36N-QEVloYFX9amz9w9Gpypajmu1shE2eS=w408-h544-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "1390 Brittain Rd"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 16928;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_a7521948-9c5b-4f27-97bb-cac3676a7ae8";
                partyPlace.coords = [41.0966097, -81.542995];
                partyPlace.name = "Ray's Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMZPVkPA6DJbZUZ4mElSjcqrny73yO8ElW4M0E5=w408-h613-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "801 W Market St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 24213;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_845f9afa-4abb-498a-b869-bb80b31bc0ce";
                partyPlace.coords = [41.0761521, -81.5231118];
                partyPlace.name = "69 Taps";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOumqgMUgZ5xCsjs0UTbNB6fApgHq9bXEHIk0Bu=w408-h306-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "370 Paul Williams St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 7680;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_51da7f00-1f55-4e5d-a459-b397373e6cf4";
                partyPlace.coords = [41.1015003, -81.467236];
                partyPlace.name = "Windsor Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPl8TfcsdCe-Rbj5e4BTE8FIlKaIrGLxbBaQs-Q=w408-h306-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "1322 E Tallmadge Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 7452;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_2c026e1f-c987-4d56-a589-16aff271cff8";
                partyPlace.coords = [41.0618385, -81.4601555];
                partyPlace.name = "Papa Don's Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMEY40LbUeEBj_sUzXkD_V1V2CAxoFkxwYgfM57=w426-h240-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "1891 E Market St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 11741;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_25b09f35-6867-409a-8a03-74393f4ca9ff";
                partyPlace.coords = [41.101682, -81.4719566];
                partyPlace.name = "Redstone Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOIfd3CWBOMg65nhFscfPXMCzLBjUtRecoJKm5s=w507-h240-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "1194 E Tallmadge Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 17391;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_338ef16c-90d5-4884-9c4a-f5071fd20bf8";
                partyPlace.coords = [41.0626203, -81.467771];
                partyPlace.name = "Market Street Grill and Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNV77JlmP08f1PAqjizvhbm69DW-GPuR_Sxbr8g=w426-h240-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "1677 E Market St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 20287;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_106c5e7c-bcb8-44fe-88e3-e826d82b1a85";
                partyPlace.coords = [41.0710452, -81.5103118];
                partyPlace.name = "Manny's Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipM5QmQZJ7KmPFSXJwDoV51mbhDDwmtmZpELQskW=w408-h306-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "394 Brown St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 1545;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_183eb61f-34b8-4cb6-b2a4-0ec03ab37a6e";
                partyPlace.coords = [41.0854085, -81.5156367];
                partyPlace.name = "High St. Hop House";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipM5Q4qKL7j8N4QZwGmYeSEHgIYWifLAIzpx9RND=w408-h272-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "20 N High St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 16374;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_7857e98c-5df9-4f6e-99e2-ab093f3c4b29";
                partyPlace.coords = [41.0724072, -81.4563139];
                partyPlace.name = "Tip'em Up Tavern";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOckzJvk3Z9PzgqSE17EOYBozPS6XUG3HUDmBN2=w408-h544-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "1854 Newton St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 13993;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_38151069-542b-47b0-9ac1-475d6a321bda";
                partyPlace.coords = [41.0709582, -81.5082511];
                partyPlace.name = "Score Draft Room";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipM66qwHhaNS1fUo2uysUPvh5oYMBFzNmHK36-Jw=w408-h306-k-no"
                partyPlace.locationTown = " Akron"
                partyPlace.locationStreet = "426 E Exchange St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 12483;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_c6fb8f42-d659-4000-92ab-c1e2fd7a042c";
                partyPlace.coords = [41.4983881, -81.6901648];
                partyPlace.name = "Flannery's Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMq2XYRIgt9k-Es5pBieIECsWeIQDe7o-jyUJbo=w408-h305-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "323 Prospect Ave E"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 23496;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_11b86660-02dc-4995-97e3-3ae11b7bb132";
                partyPlace.coords = [41.4690373, -81.6879618];
                partyPlace.name = "Rowley Inn";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipO93-iESOoyQb5CGmBtc2nc0wxNQJ6hC6TX7knq=w408-h544-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1104 Rowley Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 11658;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_78e82fac-75ed-4136-a470-14f356dc0ef0";
                partyPlace.coords = [41.4770316, -81.7727311];
                partyPlace.name = "East End Bistro & Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOtcx0iYrb3cBdH5K5i5kxVhdp0g2C6-7YIwBbi=w408-h265-k-no"
                partyPlace.locationTown = " Lakewood"
                partyPlace.locationStreet = "11922 Madison Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 17494;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_fe1f7ff5-9f64-48d3-920a-5cd82b2441dc";
                partyPlace.coords = [41.5075437, -81.6082796];
                partyPlace.name = "The Jolly Scholar";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNWC-5g5_NWH360HhskqsQlNIFVokRtKy0sVAwk=w426-h240-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "11111 Euclid Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 21055;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_09450a8b-ba19-40a8-add1-79580b39ea73";
                partyPlace.coords = [41.4995461, -81.6849492];
                partyPlace.name = "Wild Eagle Saloon";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPdVt6YU0830bN8j9MqhqOhnLQPsbwko6PMQwrc=w408-h725-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "921 Huron Rd E"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 4091;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_d2d1a29f-0e60-4873-b4dd-2c1350aeca28";
                partyPlace.coords = [41.4958502, -81.7066201];
                partyPlace.name = "McCarthy's Downtown";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNcPln8vvlwcRydFPJY_3wLW0_eII_PfDUXLnae=w408-h408-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1231 Main Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 23765;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_b19d3f49-281a-45b8-8203-ca2851094786";
                partyPlace.coords = [41.498074, -81.685811];
                partyPlace.name = "Thirsty Parrot";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNp3ok2rCrUnDmGRnZjIYk6d_8AvoQc8fmHPdCw=w408-h306-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "812 Huron Rd E"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 21571;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_c05a78b9-475b-4b9b-9294-4abe95cdc099";
                partyPlace.coords = [41.4652731, -81.7379147];
                partyPlace.name = "The Hill Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipN3lq2lQgPkD0b-BFabNQtmnaJckJIZOHQJbyxU=w426-h240-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "3256 W 73rd St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 548;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_d7ca39af-1b72-47e4-8de0-6c3577bfa3c5";
                partyPlace.coords = [41.4850769, -81.7034306];
                partyPlace.name = "Market Garden Brewery";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipO962cPTqR2KUBqHQKk3wBIe8uFSP_qjdu-SwWj=w408-h272-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1947 W 25th St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 16677;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_3dc5ad38-5a10-43d7-b756-1a5007e2f9d0";
                partyPlace.coords = [41.5011994, -81.6813435];
                partyPlace.name = "Parnell's Irish Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPL_YMmf06SkB8LONkI9fSB7zHZPtmjIvjI0Xi3=w408-h544-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1415 Euclid Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 6221;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_e29d4bc1-f7ea-4fe4-9586-859cffab9360";
                partyPlace.coords = [41.4770341, -81.7729135];
                partyPlace.name = "The Flying Rib";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNfk31GjbHrxFkdKl-MZrcEd7PMs6WmbsTiasFQ=w408-h306-k-no"
                partyPlace.locationTown = " Lakewood"
                partyPlace.locationStreet = "11926 Madison Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 21213;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_d438e465-49a6-4ba6-a731-db88f9e90e39";
                partyPlace.coords = [41.4702099, -81.7852111];
                partyPlace.name = "Kelley's Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOlCwKxzzTqpgc9WS5U95nDqhMsU9lFxj2W-9U=w408-h272-k-no"
                partyPlace.locationTown = " Lakewood"
                partyPlace.locationStreet = "13525 Lakewood Heights Blvd"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 2663;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_59a4896f-4861-45aa-ab38-a338c6c314e3";
                partyPlace.coords = [41.4866324, -81.730394];
                partyPlace.name = "Stone Mad Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOLFQVwPU27279MXdVy1B7EeZ3Fc9JE18GL5oYO=w408-h306-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1306 W 65th St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 21527;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_997b1612-8126-40a5-9019-3326e689e198";
                partyPlace.coords = [41.4994969, -81.7007411];
                partyPlace.name = "Gillespie's Map Room";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipODj1TO9IyTOmeBesBDCyADGexYUqzGfJoSIf8J=w408-h240-k-no-pi-20-ya243.22412-ro-0-fo100"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1281 W 9th St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 9823;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_6fd3d290-2514-4788-a5af-3162bcc684ab";
                partyPlace.coords = [41.4795058, -81.6843459];
                partyPlace.name = "The Flying Monkey Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPtNFl0MgKrxwDtExvke6iUBYweTl6NMdDCCZu5=w408-h545-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "819 Jefferson Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 22130;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_b891a4c9-e4a0-46d0-b13b-e80e69d7e38b";
                partyPlace.coords = [41.498716, -81.694586];
                partyPlace.name = "Lobby Court Bar";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOwIDlxF7s1Umk-VHsr8Fx3E0RN6gJZvFRlQY8R=w426-h240-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "24 Public Square"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 1172;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_847f0270-e36c-440a-8a84-cb216d93bc03";
                partyPlace.coords = [41.4766502, -81.6910248];
                partyPlace.name = "Lincoln Park Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipN4z_J7SNThqqRsZwf7aoLCak0TtIUfWbBXGr9_=w408-h544-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "2609 W 14th St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 22674;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_f2856309-197b-4618-8d9c-5786eaa84482";
                partyPlace.coords = [41.4571449, -81.7828453];
                partyPlace.name = "Charlie's Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPzmtH7Gv2RZAUjuR4Hu6ycYoL0aFxnWyupbGLk=w408-h306-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "13347 Lorain Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 23455;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_9f13a9a7-0ff8-434b-bfc0-cf353ad9d7e1";
                partyPlace.coords = [41.4806943, -81.6855744];
                partyPlace.name = "The Treehouse";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNByswkCGWdyvT17_4i8jOS58umtPaFgikYQdyA=w408-h408-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "820 College Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 20127;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_c353ed0f-fa48-4aa0-b8ef-5ee280571ac3";
                partyPlace.coords = [41.4842172, -81.7440686];
                partyPlace.name = "McNamara's Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipP51erFerHblGnI7wVs-VEI29a0u8ZH442kgrqw=w408-h306-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "8611 Lake Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 11938;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_bc6f7626-410b-42e8-96d7-7ad8269ff891";
                partyPlace.coords = [41.4801908, -81.6850546];
                partyPlace.name = "Edison's Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOEyNUVDMpRky8RFzLWcy50ymeLkSuEC4X3uAvg=w408-h271-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "2373 Professor Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 19756;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_0acce92c-e3a6-476a-95c6-4e01379fa28a";
                partyPlace.coords = [41.492705, -81.698024];
                partyPlace.name = "BrewDog Cleveland Outpost";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMDjOL3ESPqeWcFMDzSS_8JHfRr7vwDpvP7ljGQ=w408-h291-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1956 Carter Rd"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 22729;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_c38e8155-b23b-4d4d-bdfc-1ae2453b0e92";
                partyPlace.coords = [41.4699344, -81.6900585];
                partyPlace.name = "Clark Bar";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNa5y-jIu3VZiDUVg4T7IjVZGxAphhBXhJMHZUR=w408-h306-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1201 Clark Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 14065;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_e7675b40-b564-4d2c-99be-6fac78aa769b";
                partyPlace.coords = [41.4858545, -81.7373216];
                partyPlace.name = "All Saints Public House";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOrii-Op4bhwBGQBCXWXvf_E-DETqtm8ebpIFcq=w408-h306-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1261 W 76th St"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 5661;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_eb502ffc-ac33-4c60-8372-9bf458a6f367";
                partyPlace.coords = [41.449848, -81.816338];
                partyPlace.name = "P.J. McIntyre's Irish Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPZA5kvsi6sh852z7TUGPzeQJLTx_4YycMHJltG=w408-h272-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "17119 Lorain Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 9799;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_bff7e210-b5cb-4edd-a876-bdcba708137a";
                partyPlace.coords = [41.493034, -81.7070794];
                partyPlace.name = "Carney's Top of the Flats Bar";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOPPEt_6-k_QJNgz3HC_1jkqIVVQPj-u_KUbApD=w408-h306-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "1329 Washington Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 6961;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_4ff44b23-77c5-455e-b27d-4a43e8e06389";
                partyPlace.coords = [41.4311727, -81.7333422];
                partyPlace.name = "Brooklyn Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipO5e7Wfzwjx10cfeslklcauS5-jPrAvqCFxheP_=w408-h306-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "6900 Biddulph Rd"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 10814;


                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "venue_940ce0ae-55cc-4fbc-b537-3252c794dcb1";
                partyPlace.coords = [41.4397801, -81.7335373];
                partyPlace.name = "McG's Pub And Grub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOeTstjYomeYU0zhWQjcHuHHocCgiMQGRyMyJaH=w426-h240-k-no"
                partyPlace.locationTown = " Cleveland"
                partyPlace.locationStreet = "6815 Memphis Ave"
                partyPlace.locationHouseNumber = ""
                partyPlace.price = 0;
                partyPlace.followers = 12184;


                partyPlace.parties = [];

                return partyPlace;
            })(),
        ),
        townsModal: new TownsModalModel(),
        towns: Array<TownModel>(
            ((): TownModel => {
                const model = new TownModel();
                model.id = 0;
                model.name = "Cleveland, Ohio";
                model.coords = [41.497532, -81.70586]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel()
                model.id = 69
                model.name = "Columbus, Ohio"
                model.coords = [39.9829514, -82.990829]
                model.optimalZoom = 13
                return model
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 1;
                model.name = "Toledo, Ohio";
                model.coords = [41.656555, -83.5742561]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 2;
                model.name = "Cincinnati, Ohio";
                model.coords = [39.1363401, -84.5404014]
                model.optimalZoom = 13
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 3;
                model.name = "Tampa, Florida";
                model.coords = [27.9944826, -82.4542804]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 5;
                model.name = "Orlando, Florida";
                model.coords = [28.4811732, -81.3426652]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 6;
                model.name = "Jacksonville, Florida";
                model.coords = [30.34499, -81.6831069]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 7;
                model.name = "Los Angeles, California";
                model.coords = [34.0600235, -118.2249649]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 8;
                model.name = "San Francisco, California";
                model.coords = [37.7577, -122.4376]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 9;
                model.name = "Dallas, Texas";
                model.coords = [32.7700873, -96.7873425]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 10;
                model.name = "Houston, Texas";
                model.coords = [29.817178, -95.4012915]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 11;
                model.name = "Atlanta, Georgia";
                model.coords = [33.7469586, -84.3775585]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 12;
                model.name = "Portland, Oregon";
                model.coords = [45.542714, -122.6544011]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 13;
                model.name = "Las Vagas, Nevada";
                model.coords = [36.125, -115.175]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 14;
                model.name = "Phoenix, Arizona";
                model.coords = [33.6054149, -112.125051]
                model.optimalZoom = 13
                return model;
            })(),
        ),
        trendingSearchModal: new TrendingSearchModalModel(),
        modalsOpen: new Array<string>(),
        userId: 1,
        friendRequests: Array.from([6]),
        friendRequestsOutgoing: Array.from([4]),
        users: new Map<number, ProfileModel>([
            [
                1,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 1
                    profile.firstName = "Daniel"
                    profile.lastName = "Smith"
                    profile.username = "dsmith"
                    profile.birthday = new Date("2001-04-14T00:00:00.000000Z")
                    profile.friends = new Array<number>(2, 3, 5, 8, 9, 10, 11, 12, 13, 14, 15, 16)
                    return profile;
                })()
            ],
            [
                2,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 2
                    profile.firstName = "Maddie"
                    profile.lastName = "Stachera"
                    profile.username = "mads76"
                    profile.birthday = new Date("1998-03-19T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                3,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 3
                    profile.firstName = "Alexa"
                    profile.lastName = "Masten"
                    profile.username = "lexm"
                    profile.birthday = new Date("2002-07-21T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                4,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 4
                    profile.firstName = "Noelle"
                    profile.lastName = "Molnar"
                    profile.username = "nmolnar5"
                    profile.birthday = new Date("1995-05-18T00:00:00.000000Z")
                    return profile;
                })()
            ],
            [
                5,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 5
                    profile.firstName = "Nick"
                    profile.lastName = "Dimitriv"
                    profile.username = "dimitriv12"
                    profile.birthday = new Date("1977-07-12T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                6,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 6
                    profile.firstName = "Will"
                    profile.lastName = "Burns"
                    profile.username = "willburn"
                    profile.birthday = new Date("1968-09-24T00:00:00.000000Z")
                    return profile;
                })()
            ],
            [
                7,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 7
                    profile.firstName = "Emma"
                    profile.lastName = "Bellak"
                    profile.username = "emmbell"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    return profile;
                })()
            ],
            [
                8,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 8
                    profile.firstName = "Hanna"
                    profile.lastName = "Laird"
                    profile.username = "hlaird"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                9,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 9
                    profile.firstName = "Anthony"
                    profile.lastName = "Owen"
                    profile.username = "anthowen"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                10,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 10
                    profile.firstName = "Ben"
                    profile.lastName = "Jones"
                    profile.username = "benjo"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                11,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 11
                    profile.firstName = "Tori"
                    profile.lastName = "Cooper"
                    profile.username = "toricpr"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                12,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 12
                    profile.firstName = "Brooklyn"
                    profile.lastName = "Meden"
                    profile.username = "brmeden"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                13,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 13
                    profile.firstName = "Paige"
                    profile.lastName = "Peterson"
                    profile.username = "pptrsn"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                14,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 14
                    profile.firstName = "Emily"
                    profile.lastName = "Simons"
                    profile.username = "emsims"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                15,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 15
                    profile.firstName = "Ashlynn"
                    profile.lastName = "Nicholson"
                    profile.username = "ashnic"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                16,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 16
                    profile.firstName = "Jordan"
                    profile.lastName = "Congrove"
                    profile.username = "jordancg"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                17,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 16
                    profile.firstName = "Cecilia"
                    profile.lastName = "Terrasi"
                    profile.username = "ccliat"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                18,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 16
                    profile.firstName = "Maya"
                    profile.lastName = "Bertwell"
                    profile.username = "mayabrtw"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
        ]),
        friendsModal: new FriendsModalModel(),
        profileModal: new ProfileModalModel(),
        friendSearchModal: new FriendSearchModalModel(),
        placeSearch: new PlaceSearchModel(),
        placeDetailsModal: new PlaceDetailsModalModel(),
        followedPlaces: Array.from([3, 4]),
        joinedParties: Array.from([0, 1]),
        partyJoinModal: new PartyJoinModalModel(),
        inviteFriendsModal: new InviteFriendsModalModel(),
    },
    getters: {
        availableParties(state) {
            return state.availableParties;
        },
        partyDetailsModal(state) {
            return state.partyDetailsModal;
        },
        partySearch(state) {
            return state.partySearch;
        },
        trendingPage(state) {
            return state.trendingPage;
        },
        trendingPlaces(state) {
            return state.trendingPlaces;
        },
        townsModal(state) {
            return state.townsModal;
        },
        towns(state) {
            return state.towns;
        },
        trendingSearchModal(state) {
            return state.trendingSearchModal;
        },
        modalsOpen(state) {
            return state.modalsOpen
        },
        userId(state) {
            return state.userId;
        },
        friendRequests(state) {
            return state.friendRequests;
        },
        friendRequestsOutgoing(state) {
            return state.friendRequestsOutgoing;
        },
        users(state) {
            return state.users;
        },
        friendsModal(state) {
            return state.friendsModal;
        },
        profileModal(state) {
            return state.profileModal;
        },
        friendSearchModal(state) {
            return state.friendSearchModal;
        },
        placeSearch(state) {
            return state.placeSearch;
        },
        placeDetailsModal(state) {
            return state.placeDetailsModal;
        },
        followedPlaces(state) {
            return state.followedPlaces;
        },
        joinedParties(state) {
            return state.joinedParties;
        },
        partyJoinModal(state) {
            return state.partyJoinModal;
        },
        inviteFriendsModal(state) {
            return state.inviteFriendsModal;
        }
    },
    mutations: {
        addAvailableParties(state, parties: Array<PartyDetailsModel>) {
            state.availableParties = state.availableParties.concat(parties);
        },
        setAvailableParties(state, parties: Array<PartyDetailsModel>) {
            state.availableParties = parties;
        },
        setPartyDetailsModalOpen(state, boolean: boolean) {
            state.partyDetailsModal.open = boolean;
        },
        setPartyDetailsModalContent(state, party: PartyDetailsModel) {
            state.partyDetailsModal.partyDetails = party;
        },
        setPartySearch(state, search: PartySearchModel) {
            state.partySearch = search;
        },
        setPartySearchName(state, name: string) {
            state.partySearch.name = name;
        },
        setPartySearchDateFrom(state, date: Date) {
            state.partySearch.dateFrom = date;
        },
        setPartySearchPrice(state, price: number) {
            state.partySearch.price = price;
        },
        setPartySearchAge(state, age: number) {
            state.partySearch.age = age;
        },
        setPartySearchLocationTown(state, town: string) {
            state.partySearch.locationTown = town;
        },
        setPartySearchOnlyJoined(state, only: boolean) {
            state.partySearch.onlyJoined = only;
        },
        setPartySearchModalOpen(state, open: boolean) {
            state.partySearch.modalOpen = open;
        },
        clearPartySearchModal(state, ignored: any) {
            state.partySearch.clear();
        },
        setTrendingPageMap(state, map: GeoMap) {
            state.trendingPage.map = map;
        },
        setTrendingPagePopup(state, popup: Overlay) {
            state.trendingPage.popup = popup;
        },
        setTrendingPageCoords(state, coords: number[]) {
            const transformedCoords = 
                transformCoords([coords[1], coords[0]], SOURCE_PROJECTION, OSM_PROJECTION);
            state.trendingPage.map.getView().animate({
                    center: transformedCoords,
                });
        },
        setTrendingPageZoom(state, zoom: number) {
            state.trendingPage.map.getView().animate({
                zoom
            });
        },
        setTrendingPageSelectedPlace(state, place: PartyPlaceModel | undefined) {
            if (place == undefined) {
                state.trendingPage.popup.setPosition(undefined);
                return;
            }
            const transformedCoords = transformCoords([place.coords[1], place.coords[0]], SOURCE_PROJECTION, OSM_PROJECTION);
            state.trendingPage.popup.setPosition(transformedCoords);
            state.trendingPage.selectedPlace = place;
        },
        addTrendingPlaces(state, places: Array<PartyPlaceModel>) {
            state.trendingPlaces = state.trendingPlaces.concat(places);
        },
        setTrendingPlaces(state, places: Array<PartyPlaceModel>) {
            state.trendingPlaces = places;
        },
        setTownsModalOpen(state, open: boolean) {
            state.townsModal.open = open;
        },
        setTrendingSearchModalOpen(state, open: boolean) {
            state.trendingSearchModal.open = open;
        },
        addOpenModal(state, closerActionName: string) {
            state.modalsOpen.push(closerActionName);
        },
        removeOpenModal(state, closerActionName: string) {
            state.modalsOpen = state.modalsOpen.filter((action) => action != closerActionName);
        },
        setFriendsModalOpen(state, open: boolean) {
            state.friendsModal.open = open;
        },
        setProfileModalOpen(state, open: boolean) {
            state.profileModal.open = open;
        },
        setProfileModalPresentingElement(state, element: any) {
            state.profileModal.presentingElement = element;
        },
        setProfileModalChosenProfile(state, profile: ProfileModel) {
            state.profileModal.chosenProfile = profile;
        },
        setFriendSearchModalOpen(state, open: boolean) {
            state.friendSearchModal.open = open;
        },
        setFriendSearchModalPresentingElement(state, element: any) {
            state.friendSearchModal.presentingElement = element;
        },
        setFriendSearchModalQuery(state, query: string) {
            state.friendSearchModal.query = query;
        },
        setPlaceSearchName(state, name: string) {
            state.placeSearch.name = name;
        },
        setPlaceSearchPrice(state, price: number) {
            state.placeSearch.price = price;
        },
        setPlaceSearchAge(state, age: number) {
            state.placeSearch.age = age;
        },
        setPlaceSearchLocationTown(state, town: string) {
            state.placeSearch.locationTown = town;
        },
        setPlaceSearchOnlyFollowing(state, only: boolean) {
            state.placeSearch.onlyFollowing = only;
        },
        setPlaceSearchModalOpen(state, open: boolean) {
            state.placeSearch.modalOpen = open;
        },
        clearPlaceSearchModal(state, any: any) {
            state.placeSearch.clear()
        },
        setPlaceDetailsModalContent(state, content: PartyPlaceModel) {
            state.placeDetailsModal.placeDetails = content;
        },
        setPlaceDetailsModalOpen(state, open: boolean) {
            state.placeDetailsModal.open = open;
        },
        setPartyJoinModalSelectedParty(state, party: PartyDetailsModel) {
            state.partyJoinModal.selectedParty = party;
        },
        setPartyJoinModalOpen(state, open: boolean) {
            state.partyJoinModal.modalOpen = open;
        },
        setPartyJoinModalPartyModalRef(state, ref: any) {
            state.partyJoinModal.partyModalRef = ref;
        },
        setInviteFriendsModalQuery(state, query: string) {
            state.inviteFriendsModal.query = query;
        },
        setInviteFriendsModalInvitedFriends(state, invitedFriends: Array<number>) {
            state.inviteFriendsModal.invitedFriends = invitedFriends;
        },
        addInviteFriendsModalInvitedFriends(state, invitedFriends: Array<number>) {
            state.inviteFriendsModal.invitedFriends = state.inviteFriendsModal.invitedFriends.concat(invitedFriends);
        },
        removeInviteFriendsModalInvitedFriends(state, friends: Array<number>) {
            state.inviteFriendsModal.invitedFriends = state.inviteFriendsModal.invitedFriends
                .filter((uid: number) => !friends.includes(uid));
        },
        setInviteFriendsModalOpen(state, open: boolean) {
            state.inviteFriendsModal.open = open;
        },
        setInviteFriendsModalPartyJoinModalRef(state, ref: any) {
            state.inviteFriendsModal.partyJoinModalRef = ref;
        }
    },
    actions: {
        addAvailableParties({ commit }, parties: Array<PartyDetailsModel>) {
            commit("addAvailableParties", parties);
        },
        setAvailableParties({commit}, parties: Array<PartyDetailsModel>) {
            commit("setAvailableParties", parties);
        },
        setPartyDetailsModalOpen({ commit }, open: boolean) {
            commit("setPartyDetailsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPartyDetailsModalOpen")
        },
        setPartyDetailsModalContent({commit}, party: PartyDetailsModel) {
            commit("setPartyDetailsModalContent", party);
        },
        setPartySearch({commit}, search: PartySearchModel) {
            commit("setPartySearch", search);
        },
        setPartySearchName({commit}, name: string) {
            commit("setPartySearchName", name);
        },
        setPartySearchDateFrom({commit}, date: Date) {
            commit("setPartySearchDateFrom", date);
        },
        setPartySearchPrice({commit}, free: boolean) {
            commit("setPartySearchPrice", free);
        },
        setPartySearchAge({commit}, age: number) {
            commit("setPartySearchAge", age);
        },
        setPartySearchLocationTown({commit}, town: string) {
            commit("setPartySearchLocationTown", town);
        },
        setPartySearchOnlyJoined({commit}, only: boolean) {
            commit("setPartySearchOnlyJoined", only);
        },
        setPartySearchModalOpen({commit}, open: boolean) {
            commit("setPartySearchModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPartySearchModalOpen")
        },
        clearPartySearchModal({commit}, any: any) {
            commit("clearPartySearchModal", any);
        },
        setTrendingPageMap({commit}, map: GeoMap) {
            commit("setTrendingPageMap", map);
        },
        setTrendingPagePopup({commit}, popup: Overlay) {
            commit("setTrendingPagePopup", popup);
        },
        setTrendingPageCoords({commit}, coords: number[]) {
            commit("setTrendingPageCoords", coords);
        },
        setTrendingPageZoom({commit}, zoom: number) {
            commit("setTrendingPageZoom", zoom);
        },
        setTrendingPageSelectedPlace({commit}, place: PartyPlaceModel) {
            commit("setTrendingPageSelectedPlace", place);
        },
        addTrendingPlaces({commit}, places: Array<PartyPlaceModel>) {
            commit("addTrendingPlaces", places);
        },
        setTrendingPlaces({commit}, places: Array<PartyPlaceModel>) {
            commit("setTrendingPlaces", places);
        },
        setTownsModalOpen({commit}, open: boolean) {
            commit("setTownsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setTownsModalOpen")
        },
        setTrendingSearchModalOpen({commit}, open: boolean) {
            commit("setTrendingSearchModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setTrendingSearchModalOpen")
        },
        addOpenModal({commit}, closerActionName: string) {
            commit("addOpenModal", closerActionName);
        },
        removeOpenModal({commit}, closerActionName: string) {
            commit("removeOpenModal", closerActionName);
        },
        setFriendsModalOpen({commit}, open: boolean) {
            commit("setFriendsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setFriendsModalOpen")
        },
        setProfileModalOpen({commit}, open: boolean) {
            commit("setProfileModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setProfileModalOpen")
        },
        setProfileModalPresentingElement({commit}, element: any) {
            commit("setProfileModalPresentingElement", element);
        },
        setProfileModalChosenProfile({commit}, profile: ProfileModel) {
            commit("setProfileModalChosenProfile", profile);
        },
        setFriendSearchModalOpen({commit}, open: boolean) {
            commit("setFriendSearchModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setFriendSearchModalOpen")
        },
        setFriendSearchModalPresentingElement({commit}, element: any) {
            commit("setFriendSearchModalPresentingElement", element);
        },
        setFriendSearchModalQuery({commit}, query: string) {
            commit("setFriendSearchModalQuery", query);
        },
        setPlaceSearchName({commit}, name: string) {
            commit("setPlaceSearchName", name);
        },
        setPlaceSearchPrice({commit}, price: number) {
            commit("setPlaceSearchPrice", price);
        },
        setPlaceSearchAge({commit}, age: number) {
            commit("setPlaceSearchAge", age);
        },
        setPlaceSearchLocationTown({commit}, town: string) {
            commit("setPlaceSearchLocationTown", town);
        },
        setPlaceSearchOnlyFollowing({commit}, only: boolean) {
            commit("setPlaceSearchOnlyFollowing", only);
        },
        setPlaceSearchModalOpen({commit}, open: boolean) {
            commit("setPlaceSearchModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPlaceSearchModalOpen")
        },
        clearPlaceSearchModal({commit}, any: any) {
            commit("clearPlaceSearchModal", any);
        },
        setPlaceDetailsModalContent({commit}, content: PartyPlaceModel) {
            commit("setPlaceDetailsModalContent", content);
        },
        setPlaceDetailsModalOpen({commit}, open: boolean) {
            commit("setPlaceDetailsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPlaceDetailsModalOpen")
        },
        setPartyJoinModalSelectedParty({commit}, party: PartyDetailsModel) {
            commit("setPartyJoinModalSelectedParty", party);
        },
        setPartyJoinModalOpen({commit}, open: boolean) {
            commit("setPartyJoinModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPartyJoinModalOpen")
        },
        setPartyJoinModalPartyModalRef({commit}, ref: any) {
            commit("setPartyJoinModalPartyModalRef", ref);
        },
        setInviteFriendsModalQuery({commit}, query: string) {
            commit("setInviteFriendsModalQuery", query);
        },
        setInviteFriendsModalInvitedFriends({commit}, invitedFriends: Array<number>) {
            commit("setInviteFriendsModalInvitedFriends", invitedFriends);
        },
        addInviteFriendsModalInvitedFriends({commit}, invitedFriends: Array<number>) {
            commit("addInviteFriendsModalInvitedFriends", invitedFriends);
        },
        removeInviteFriendsModalInvitedFriends({commit}, friends: Array<number>) {
            commit("removeInviteFriendsModalInvitedFriends", friends);
        },
        setInviteFriendsModalOpen({commit}, open: boolean) {
            commit("setInviteFriendsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setInviteFriendsModalOpen")
        },
        setInviteFriendsModalPartyJoinModalRef({commit}, ref: any) {
            commit("setInviteFriendsModalPartyJoinModalRef", ref);
        }
    },
    modules: {},
});
