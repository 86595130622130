
import { defineComponent } from "vue";
import { IonButton, IonIcon } from "@ionic/vue";
export default defineComponent({
    name: "FatButton",
    props: {
        color: String,
        icon: String,
        text: String,
        secondary: Boolean,
    },
    components: {
        IonButton,
        IonIcon,
    },
    setup(props) {
        return {
            icon__: props.icon,
        };
    },
});
