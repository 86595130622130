
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import store from "@/store";

import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonSearchbar,
    IonButtons,
    IonButton,
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonList,
    IonItem,
    IonLabel,
    IonItemGroup,
    IonItemDivider,
    IonImg,
} from "@ionic/vue";
import PartyPlaceModel from "@/store/models/PartyPlaceModel";
export default defineComponent({
    name: "TownsModal",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonSegment,
        IonSegmentButton,
        IonList,
        IonItem,
        IonLabel,
        IonSearchbar,
        IonContent,
        IonItemGroup,
        IonItemDivider,
        IonImg,
    },
    data: () => ({
        q: "",
        slider: "name",
        placeSearchByName: [],
        ptExact: [],
        ptStartsWith: new Map(),
        ptIncludes: new Map(),
        ptReverseIncludes: new Map(),
    }),
    computed: {
        ...mapGetters(["trendingSearchModal", "trendingPlaces"]),
    },
    methods: {
        dismiss: function () {
            store.dispatch("setTrendingSearchModalOpen", false);
        },
        noResults: function () {
            return (
                (this.slider == "name" && this.placeSearchByName.length == 0) ||
                (this.slider == "location" &&
                    this.ptExact.length == 0 &&
                    this.ptStartsWith.size == 0 &&
                    this.ptIncludes.size == 0 &&
                    this.ptReverseIncludes.size == 0)
            );
        },
        open: function (place: PartyPlaceModel) {
            this.$router.push("/tabs/trending");
            this.dismiss();
            store.dispatch("setTrendingPageSelectedPlace", place);
        },
        search: function (query: string) {
            this.q = query;
        },
        searchInput: function (e: string) {
            e = e.toLowerCase();
            if (e == null || e.length == 0) {
                this.placeSearchByName = [];
                this.ptExact = [];
                this.ptStartsWith = new Map();
                this.ptIncludes = new Map();
                this.ptReverseIncludes = new Map();
                return;
            }
            this.placeSearchByName = this.trendingPlaces.filter(
                (place: PartyPlaceModel) => {
                    return (
                        e.includes(place.name.toLowerCase()) ||
                        place.name.toLowerCase().includes(e)
                    );
                }
            );
            let candidates = this.trendingPlaces.filter(
                (place: PartyPlaceModel) => {
                    return (
                        e.includes(place.locationTown.toLowerCase()) ||
                        place.locationTown.toLowerCase().includes(e)
                    );
                }
            );
            let townNamesCapitalized = new Map<string, string>();
            let towns = Array.from(
                new Set<string>(
                    candidates.map((place: PartyPlaceModel) => {
                        townNamesCapitalized.set(
                            place.locationTown.toLowerCase(),
                            place.locationTown
                        );
                        return place.locationTown.toLowerCase();
                    })
                )
            );
            let placesTownExact = candidates.filter(
                (place: PartyPlaceModel) =>
                    place.locationTown.toLowerCase() == e
            );
            let placesTownStartingWith = new Map<
                string,
                Array<PartyPlaceModel>
            >();
            towns
                .filter((town: string) => town != e && town.startsWith(e))
                .forEach((town: string) => {
                    placesTownStartingWith.set(
                        townNamesCapitalized.get(town) || "Error",
                        candidates.filter(
                            (place: PartyPlaceModel) =>
                                place.locationTown.toLowerCase() == town
                        )
                    );
                });
            let placesTownIncludes = new Map<string, Array<PartyPlaceModel>>();
            towns
                .filter(
                    (town: string) => !town.startsWith(e) && town.includes(e)
                )
                .forEach((town: string) => {
                    placesTownIncludes.set(
                        townNamesCapitalized.get(town) || "Error",
                        candidates.filter(
                            (place: PartyPlaceModel) =>
                                place.locationTown.toLowerCase() == town
                        )
                    );
                });
            let placesTownReverseIncludes = new Map<
                string,
                Array<PartyPlaceModel>
            >();
            towns
                .filter((town: string) => !town.includes(e) && e.includes(town))
                .forEach((town: string) => {
                    placesTownReverseIncludes.set(
                        townNamesCapitalized.get(town) || "Error",
                        candidates.filter(
                            (place: PartyPlaceModel) =>
                                place.locationTown.toLowerCase() == town
                        )
                    );
                });

            this.ptExact = placesTownExact;
            this.ptStartsWith = placesTownStartingWith;
            this.ptIncludes = placesTownIncludes;
            this.ptReverseIncludes = placesTownReverseIncludes;
        },
    },
});
