
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import store from "@/store";

import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonSearchbar,
    IonImg,
    IonAvatar,
    IonLabel,
    IonItem,
    IonList,
} from "@ionic/vue";
import ProfileModel from "@/store/models/ProfileModel";

export default defineComponent({
    name: "FriendSearchModal",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonContent,
        IonSearchbar,
        IonImg,
        IonAvatar,
        IonLabel,
        IonItem,
        IonList,
    },
    computed: {
        ...mapGetters(["friendSearchModal"]),
        results: function () {
            const query = store.getters.friendSearchModal.query.toLowerCase();
            return Array.from(store.getters.users)
                .filter((array: any) => {
                    const profile = array[1] as ProfileModel;
                    return (
                        profile.firstName.toLowerCase().includes(query) ||
                        profile.lastName.toLowerCase().includes(query) ||
                        profile.username.toLowerCase().includes(query) ||
                        query.includes(profile.firstName.toLowerCase()) ||
                        query.includes(profile.lastName.toLowerCase()) ||
                        query.includes(profile.username.toLowerCase())
                    );
                })
                .map((array: any) => array[1]);
        },
        query: {
            get() {
                return store.getters.friendSearchModal.query;
            },
            set(query: string) {
                store.dispatch("setFriendSearchModalQuery", query);
            },
        },
    },
    methods: {
        dismiss: function () {
            store.dispatch("setFriendSearchModalOpen", false);
        },
        openProfile: function (profile: ProfileModel) {
            store.dispatch("setProfileModalChosenProfile", profile);
            store.dispatch("setProfileModalOpen", true);
        },
    },
});
