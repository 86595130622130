export default class TrendingSearchModalModel {

    open: boolean
    type: string
    query: string

    constructor() {
        this.open = false;
        this.type = "places"
        this.query = ""
    }

}