<template>
    <ion-row>
        <ion-col @click="openModal">
            <ion-grid class="party">
                <ion-row>
                    <ion-img
                        :src="details.imageLocation"
                        class="party-image"
                    ></ion-img>
                </ion-row>
                <ion-row class="ion-justify-content-between party-bottom">
                    <ion-col size="2fr">
                        <ion-row>
                            <ion-col class="ion-no-padding max-width">
                                <strong>{{ details.title }}</strong>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding max-width">
                                <small>{{ details.getLocation() }}</small>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding max-width">
                                <small>{{
                                    details.age > 0 ? details.age + "+" : ""
                                }}</small>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col size="1fr" class="down">
                        <ion-row>
                            <ion-col class="ion-no-padding right">
                                <small>
                                    {{ details.getTimeFrom() }} to
                                    {{ details.getTimeTo() }}
                                </small>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding right">
                                <small>
                                    {{ details.getTimeUntil() }}
                                </small>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding right">
                                <small>
                                    {{
                                        details.price == 0
                                            ? "Free"
                                            : "$" + details.price
                                    }}
                                </small>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-col>
    </ion-row>
</template>

<script lang="js">
import PartyDetailsModel from "@/store/models/PartyDetailsModel";
import { IonItem, IonGrid, IonRow, IonCol, IonImg } from "@ionic/vue";
import store from "@/store";


export default {
    name: "ExploreContainer",
    props: {
        details: PartyDetailsModel,
    },
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonImg
    },
    methods: {
        openModal: async function () {
            console.log("Opened");
            //store.commit("setPartyDetailModalOpen", true)
            store.dispatch("setPartyDetailsModalContent", this.details)
            store.dispatch("setPartyDetailsModalOpen", true)
        },
    },
};
</script>

<style scoped>
.max-width {
    max-width: 42vw !important;
}
.max-width strong,
.max-width small {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.right {
    display: flex;
    justify-content: flex-end;
}
.party {
    position: relative;
    margin-bottom: -25px;
}
.party-image {
    object-fit: cover;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    box-shadow: 0px 3px 20px -5px rgba(0, 0, 0, 0.75);
}
.down {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.party-bottom {
    background-color: var(--ion-card-background);
    padding: 5px 10px;
    border-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 3px 20px -5px rgba(0, 0, 0, 0.75);
    transform: translateY(-50%);
}
</style>