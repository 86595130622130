import TimeSuffixer from "@/utils/TimeSuffixer"

export default class PartyDetailsModel {

    id: number
    title: string
    locationTown: string
    locationStreet: string
    locationHouseNumber: string
    timeFrom: Date
    timeTo: Date
    imageLocation: string
    description: string
    price: number
    age: number

    constructor() {
        this.id = -1
        this.title = ""
        this.locationTown = ""
        this.locationStreet = ""
        this.locationHouseNumber = ""
        this.timeFrom = new Date()
        this.timeTo = new Date()
        this.imageLocation = ""
        this.description = ""
        this.price = -1;
        this.age = -1;
    }

    public getTimeFrom(): string {
        const hours = this.timeFrom.getHours()
        const minutes = this.timeFrom.getMinutes()
        return hours + (hours == 0 ? "0" : "") + ":" + minutes + (minutes == 0 ? "0" : "")
    }

    public getTimeTo(): string {
        const hours = this.timeTo.getHours()
        const minutes = this.timeTo.getMinutes()
        return hours + (hours == 0 ? "0" : "") + ":" + minutes + (minutes == 0 ? "0" : "")
    }

    public getTimeUntil(): string {
        const date = Date.parse(this.timeFrom.toString()) < Date.now() ? new Date(Date.now()) : this.timeFrom;
        return TimeSuffixer.friendlyDate(date);
    }

    public getLocation(): string {
        return this.locationTown + ", " + this.locationStreet + " " + this.locationHouseNumber
    }

}