
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonLabel,
    IonChip,
    IonInput,
    IonIcon,
    IonRange,
    IonToggle,
    IonAlert,
} from "@ionic/vue";

import FatButton from "@/components/ui/FatButton.vue";
import FatButtonContainer from "@/components/ui/FatButtonContainer.vue";
import FatButtonFloat from "@/components/ui/FatButtonFloat.vue";

import { trash, searchOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

import { mapGetters, mapActions } from "vuex";
import store from "@/store";

export default defineComponent({
    name: "PlaceSearch",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonLabel,
        IonChip,
        IonInput,
        IonIcon,
        IonRange,
        IonToggle,
        IonAlert,
        FatButton,
        FatButtonContainer,
        FatButtonFloat,
    },
    data: () => ({
        openedFilter: "none",
        dateModalOpen: false,
    }),
    computed: {
        name: {
            get(): string {
                return store.getters.placeSearch.name;
            },
            set(n: string) {
                store.dispatch("setPlaceSearchName", n);
            },
        },
        town: {
            get(): string {
                return store.getters.placeSearch.locationTown;
            },
            set(n: string) {
                store.dispatch("setPlaceSearchLocationTown", n);
            },
        },
        price: {
            get(): number {
                return store.getters.placeSearch.price;
            },
            set(n: number) {
                store.dispatch("setPlaceSearchPrice", n);
            },
        },
        age: {
            get(): number {
                return store.getters.placeSearch.age;
            },
            set(n: number) {
                store.dispatch("setPlaceSearchAge", n);
            },
        },
        onlyFollowing: {
            get(): boolean {
                return store.getters.placeSearch.onlyFollowing;
            },
            set(n: boolean) {
                store.dispatch("setPlaceSearchOnlyFollowing", n);
            },
        },
    },
    methods: {
        ...mapGetters(["placeSearch"]),
        dismiss: function () {
            store.dispatch("setPlaceSearchModalOpen", false);
        },
        caps: function (input: string) {
            return input.charAt(0).toUpperCase() + input.slice(1);
        },
        followingChange: function (event: any) {
            this.onlyFollowing = event.detail.checked;
        },
        clearFilters: function () {
            this.name = "";
            this.town = "";
            this.price = -1;
            this.age = -1;
            this.openedFilter = "none";
        },
        focusOn: function (ref: string) {
            setTimeout(() => {
                (this.$refs[ref] as any).$el.setFocus();
            }, 100);
        },
        checkNext: function (e: any, goto: string) {
            if (e.charCode != 13) return;
            switch (goto) {
                case "town":
                    this.openedFilter = "town";
                    this.focusOn("townInput");
                    return;
                case "date":
                    this.dateModalOpen = true;
                    return;
            }
        },
        submission: function (e: any, goto: string) {
            switch (goto) {
                case "town":
                    this.openedFilter = "town";
                    this.focusOn("townInput");
                    return;
                case "date":
                    this.dateModalOpen = true;
                    return;
            }
        },
    },
    setup() {
        const clearAlertShown = ref(false);
        const clearAlert = (state: boolean) => (clearAlertShown.value = state);
        const clearAlertButtons = [
            {
                text: "No",
                role: "cancel",
            },
            {
                text: "Yes",
                handler: () => {
                    store.getters.placeSearch.clear();
                },
            },
        ];
        return {
            trash,
            searchOutline,
            clearAlertShown,
            clearAlert,
            clearAlertButtons,
        };
    },
});
