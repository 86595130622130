export default class TownModel {

    id: number
    name: string
    coords: number[]
    optimalZoom: number

    constructor() {
        this.id = -1
        this.name = ""
        this.coords = []
        this.optimalZoom = 10
    }

}