
import {
    IonApp,
    IonRouterOutlet,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonInput,
    IonCardContent,
    IonImg,
    IonSlide,
    IonSlides,
} from "@ionic/vue";
import { isMobile } from "mobile-device-detect";
import { defineComponent, ref } from "vue";
import PartyModal from "@/components/PartyModal.vue";
import PartySearch from "@/components/PartySearch.vue";
import TownsModal from "@/components/TownsModal.vue";
import TrendingSearchModal from "@/components/TrendingSearchModal.vue";
import FriendsModal from "@/components/FriendsModal.vue";
import ProfileModal from "@/components/ProfileModal.vue";
import FriendSearchModal from "@/components/FriendSearchModal.vue";
import PlaceModal from "@/components/PlaceModal.vue";
import PlaceSearch from "@/components/PlaceSearch.vue";
import PartyJoinModal from "@/components/PartyJoinModal.vue";
import InviteFriendsModal from "@/components/InviteFriendsModal.vue";

export default defineComponent({
    name: "App",
    components: {
        IonApp,
        IonRouterOutlet,
        IonContent,
        IonCard,
        IonCardHeader,
        IonCardSubtitle,
        IonCardTitle,
        IonInput,
        IonCardContent,
        IonImg,
        IonSlide,
        IonSlides,
        PartyModal,
        PartySearch,
        TownsModal,
        TrendingSearchModal,
        FriendsModal,
        ProfileModal,
        FriendSearchModal,
        PlaceModal,
        PlaceSearch,
        PartyJoinModal,
        InviteFriendsModal,
    },
    data: () => ({
        ready: false,
        password: "",
        inputDisabled: true,
        inputClicks: 0,
        inputClickDate: new Date("2000-01-01T00:00:00.000000Z"),
        preloaderHidden: false,
    }),
    computed: {
        isLocked: function () {
            //console.log("!!!%")
            //console.log(new Date() > new Date("2022-06-12T00:00:00.000000Z"))
            //return this.isAppLocked() && new Date() > new Date("2023-07-01T00:00:00.000000Z");
            return false;
        },
        isDesktop: function () {
            return !isMobile
        }
    },
    methods: {
        checkPassword: function () {
            const key = this.password;
            let hash = 0;
            for (let i = 0; i < key.length; i++) {
                let char = key.charCodeAt(i);
                hash = (hash << 5) - hash + char;
                hash = hash & hash;
            }
            if (hash == 1430219114) {
                localStorage.setItem("app-key", key);
                this.$router.go(0);
            }
        },
        clickInput: function () {
            if (this.inputClickDate.getMinutes() != new Date().getMinutes()) {
                this.inputClicks = 0;
                this.inputClickDate = new Date();
                return;
            }
            this.inputClicks++;
            if (this.inputClicks == 30) {
                this.inputDisabled = false;
            }
        },
        loadImages: function () {
            (this.$refs.slides as any).$el.slideTo(3);
            this.hidePreloader();
        },
        hidePreloader: function () {
            if (this.preloaderHidden) return;
            this.preloaderHidden = true;
            setTimeout(() => {
                document.getElementById("preloader")?.classList.add("loaded");
                (this.$refs.slides as any).$el.slideTo(0);
            }, 2000);
            setTimeout(() => {
                document.getElementById("preloader")?.remove();
            }, 5000);
        },
    },
    mounted() {
        this.ready = true;
        this.hidePreloader();
    },
    setup() {
        const isAppLocked = function () {
            const key = localStorage.getItem("app-key");
            if (key == null) return true;
            let hash = 0;
            for (let i = 0; i < key.length; i++) {
                let char = key.charCodeAt(i);
                hash = (hash << 5) - hash + char;
                hash = hash & hash;
            }
            if (hash == 1430219114) {
                //console.log("App unlocked");
            }
            return hash != 1430219114;
        };
        let progress = ref(0);
        let mapUrl = ref("");
        let activitiesUrl = ref("");
        let friendsUrl = ref("");
        let searchUrl = ref("");
        if (isAppLocked()) {
            fetch("./assets/image/proto/map.png").then(async (response) => {
                const blob = await response.blob();
                mapUrl.value = await URL.createObjectURL(blob);
                progress.value++;
            });
            fetch("./assets/image/proto/activities.png").then(
                async (response) => {
                    const blob = await response.blob();
                    activitiesUrl.value = await URL.createObjectURL(blob);
                    progress.value++;
                }
            );
            fetch("./assets/image/proto/friends.png").then(async (response) => {
                const blob = await response.blob();
                friendsUrl.value = await URL.createObjectURL(blob);
                progress.value++;
            });
            fetch("./assets/image/proto/search.png").then(async (response) => {
                const blob = await response.blob();
                searchUrl.value = await URL.createObjectURL(blob);
                progress.value++;
            });
            window.onload = () => {
                progress.value++;
            };
        }
        return {
            isAppLocked,
            mapUrl,
            activitiesUrl,
            friendsUrl,
            searchUrl,
            progress,
        };
    },
});
