import PartyDetailsModel from "./PartyDetailsModel"

export default class PartyDetailsModalModel {

    open: boolean
    partyDetails: PartyDetailsModel

    constructor() {
        this.open = false
        this.partyDetails = new PartyDetailsModel()
    }
}