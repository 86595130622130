import PartyDetailsModel from "./PartyDetailsModel"

export default class PartyJoinModalModel {

    selectedParty: PartyDetailsModel
    modalOpen: boolean
    partyModalRef: any

    constructor() {
        this.selectedParty = new PartyDetailsModel()
        this.modalOpen = false
    }

}