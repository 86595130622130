export default class PartySearchModel {

    name: string
    dateFrom: Date
    price: number
    age: number
    locationTown: string
    onlyJoined: boolean
    modalOpen: boolean

    constructor() {
        this.name = ""
        this.dateFrom = new Date("2000-01-01T00:00:00.000000Z")
        this.price = -1
        this.age = -1
        this.locationTown = ""
        this.onlyJoined = false
        this.modalOpen = false
    }

    isActive() {
        return this.name != "" || this.dateFrom.getFullYear() != 2000 || this.price != -1 || this.age != -1 || this.locationTown != "" || this.onlyJoined
    }

    clear() {
        this.name = ""
        this.dateFrom = new Date("2000-01-01T00:00:00.000000Z")
        this.price = -1
        this.age = -1
        this.locationTown = ""
        this.onlyJoined = false
    }
}