export default class InviteFriendsModalModel {

    query: string
    invitedFriends: Array<number>
    open: boolean
    partyJoinModalRef: any

    constructor() {
        this.query = ""
        this.invitedFriends = []
        this.open = false;
    }

}