import { createRouter, createWebHistory, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

import store from "@/store";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/explore-parties'
    },
    {
        path: '/tabs/',
        component: TabsPage,
        children: [
            {
                path: '',
                redirect: '/tabs/explore-parties'
            },
            {
                path: 'explore-parties',
                component: () => import('@/views/ExplorePartiesPage.vue')
            },
            {
                path: 'explore-places',
                component: () => import('@/views/ExplorePlacesPage.vue')
            },
            {
                path: 'map',
                component: () => import("@/views/MapPage.vue")
            }
        ]
    }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

let magicDone = false;

setTimeout(() => {
    router.push("/router-magic");
}, 1);
setTimeout(() => {
    router.push("/");
    magicDone = true;
}, 2);

router.beforeEach((to, from, next) => {
    if(from.fullPath == to.fullPath) return;
    if(store.getters.modalsOpen.length == 0) {
        next();
        if(to.fullPath == "/router-magic" && magicDone) {
            router.back();
        }
        return;
    }
    store.dispatch(store.getters.modalsOpen[store.getters.modalsOpen.length - 1], false);
    router.forward();
})

export default router
