import { Map, Overlay } from "ol";
import PartyPlaceModel from "./PartyPlaceModel";

export default class TrendingPageModel {

    map: Map
    selectedPlace: PartyPlaceModel
    popup: Overlay

    constructor() {
        this.map = new Map({});
        this.selectedPlace = new PartyPlaceModel();
        this.popup = new Overlay({});
    }

}