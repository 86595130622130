
import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonIcon,
    IonPage,
    IonRouterOutlet,
} from "@ionic/vue";
import {
    globeOutline,
    musicalNotesOutline,
    wineOutline,
    personOutline,
} from "ionicons/icons";
import store from "@/store";
import ProfileModel from "@/store/models/ProfileModel";

export default {
    components: {
        IonTabs,
        IonTabBar,
        IonTabButton,
        IonIcon,
        IonPage,
        IonRouterOutlet,
    },
    methods: {
        profile: function () {
            store.dispatch("setProfileModalPresentingElement", null);
            store.dispatch(
                "setProfileModalChosenProfile",
                ProfileModel.fromId(1)
            );
            store.dispatch("setProfileModalOpen", true);
        },
    },
    setup() {
        return {
            globeOutline,
            musicalNotesOutline,
            wineOutline,
            personOutline,
        };
    },
};
