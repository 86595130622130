
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonLabel,
    IonChip,
    IonInput,
    IonIcon,
    IonDatetime,
    IonRange,
    IonToggle,
    IonAlert,
} from "@ionic/vue";

import FatButton from "@/components/ui/FatButton.vue";
import FatButtonContainer from "@/components/ui/FatButtonContainer.vue";
import FatButtonFloat from "@/components/ui/FatButtonFloat.vue";

import { trash, searchOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

import { mapGetters, mapActions } from "vuex";
import store from "@/store";

import TimeSuffixer from "@/utils/TimeSuffixer";

export default defineComponent({
    name: "PartySearch",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonLabel,
        IonChip,
        IonInput,
        IonIcon,
        IonDatetime,
        IonRange,
        IonToggle,
        FatButton,
        FatButtonContainer,
        FatButtonFloat,
        IonAlert,
    },
    data: () => ({
        openedFilter: "none",
        windowHeight: window.innerHeight,
        dateModalOpen: false,
    }),
    computed: {
        name: {
            get(): string {
                return store.getters.partySearch.name;
            },
            set(n: string) {
                store.dispatch("setPartySearchName", n);
            },
        },
        town: {
            get(): string {
                return store.getters.partySearch.locationTown;
            },
            set(n: string) {
                store.dispatch("setPartySearchLocationTown", n);
            },
        },
        date: {
            get(): string {
                if (store.getters.partySearch.dateFrom.getFullYear() == 2000)
                    return "";
                return TimeSuffixer.friendlyDate(
                    store.getters.partySearch.dateFrom
                );
            },
            set(n: string) {
                store.dispatch("setPartySearchDateFrom", new Date(n));
            },
        },
        price: {
            get(): number {
                return store.getters.partySearch.price;
            },
            set(n: number) {
                store.dispatch("setPartySearchPrice", n);
            },
        },
        age: {
            get(): number {
                return store.getters.partySearch.age;
            },
            set(n: number) {
                store.dispatch("setPartySearchAge", n);
            },
        },
        onlyJoined: {
            get(): boolean {
                return store.getters.partySearch.onlyJoined;
            },
            set(n: boolean) {
                store.dispatch("setPartySearchOnlyJoined", n);
            },
        },
    },
    methods: {
        ...mapGetters(["partySearch"]),
        ...mapActions([
            "setPartySearchName",
            "setPartySearchDateFrom",
            "setPartySearchTimeFrom",
            "setPartySearchTimeTo",
            "setPartySearchFree",
            "setPartySearchPriceMinimum",
            "setPartySearchPriceMaximum",
            "setPartySearchLocationTown",
        ]),
        dismiss: function () {
            store.dispatch("setPartySearchModalOpen", false);
        },
        openDateModal: function () {
            this.dateModalOpen = true;
        },
        closeDateModal: function () {
            this.dateModalOpen = false;
        },
        dateCancel: function () {
            this.date = "2000-01-01 00:00:00";
        },
        dateChange: function (any: any) {
            this.date = any.detail.value;
        },
        caps: function (input: string) {
            return input.charAt(0).toUpperCase() + input.slice(1);
        },
        participationChange: function (event: any) {
            this.onlyJoined = event.detail.checked;
        },
        focusOn: function (ref: string) {
            setTimeout(() => {
                (this.$refs[ref] as any).$el.setFocus();
            }, 100);
        },
        checkNext: function (e: any, goto: string) {
            if (e.charCode != 13) return;
            switch (goto) {
                case "town":
                    this.openedFilter = "town";
                    this.focusOn("townInput");
                    return;
                case "date":
                    this.dateModalOpen = true;
                    return;
            }
        },
        submission: function (e: any, goto: string) {
            switch (goto) {
                case "town":
                    this.openedFilter = "town";
                    this.focusOn("townInput");
                    return;
                case "date":
                    this.dateModalOpen = true;
                    return;
            }
        },
    },
    setup() {
        const clearAlertShown = ref(false);
        const clearAlert = (state: boolean) => (clearAlertShown.value = state);
        const clearAlertButtons = [
            {
                text: "No",
                role: "cancel",
            },
            {
                text: "Yes",
                handler: () => {
                    store.getters.partySearch.clear();
                },
            },
        ];
        return {
            trash,
            searchOutline,
            clearAlertShown,
            clearAlert,
            clearAlertButtons,
        };
    },
});
