
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import store from "@/store";

import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonList,
    IonItem,
    IonAvatar,
    IonItemDivider,
    IonItemGroup,
    IonImg,
} from "@ionic/vue";
import ProfileModel from "@/store/models/ProfileModel";

export default defineComponent({
    name: "FrinedsModal",
    data: () => ({
        slider: "friends",
    }),
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonSegment,
        IonSegmentButton,
        IonLabel,
        IonList,
        IonItem,
        IonAvatar,
        IonItemDivider,
        IonItemGroup,
        IonImg,
    },
    computed: {
        ...mapGetters(["friendsModal", "friends", "users"]),
        friends: function () {
            const friends = store.getters.users.get(
                store.getters.userId
            ).friends;
            return Array.from(store.getters.users)
                .filter((array: any) => {
                    return friends.includes(array[0]);
                })
                .map((array: any) => array[1]);
        },
        requests: function () {
            const reqs = store.getters.friendRequests;
            return Array.from(store.getters.users)
                .filter((array: any) => {
                    return reqs.includes(array[0]);
                })
                .map((array: any) => array[1]);
        },
        requestsOutgoing: function () {
            const reqsOut = store.getters.friendRequestsOutgoing;
            return Array.from(store.getters.users)
                .filter((array: any) => {
                    return reqsOut.includes(array[0]);
                })
                .map((array: any) => array[1]);
        },
    },
    methods: {
        dismiss: function () {
            store.dispatch("setFriendsModalOpen", false);
        },
        search: function () {
            store.dispatch(
                "setFriendSearchModalPresentingElement",
                this.$refs.friendsModal
            );
            store.dispatch("setFriendSearchModalOpen", true);
        },
        hasBirthdayToday: function (birthday: Date) {
            const now = new Date();
            return (
                birthday.getMonth() == now.getMonth() &&
                birthday.getDate() == now.getDate()
            );
        },
        openProfile: function (profile: ProfileModel) {
            console.log("asd");
            store.dispatch(
                "setProfileModalPresentingElement",
                this.$refs.friendsModal
            );
            store.dispatch("setProfileModalChosenProfile", profile);
            store.dispatch("setProfileModalOpen", true);
        },
    },
});
